import React from "react"
import { Link } from "gatsby"

export default class Hotel extends React.Component {
  render() {
    return (
      <div className="main">
        <div className="border-bottom-wrapper">
          <div className="border-bottom">
            <div className="cover-grid">
              <div className="column column-border-wrapper">
                <div className="column-border">
                  <div className="column-cell">
                    <h2>Stay with us!</h2>
                    <h4 style={{ margin: "20px 10px", lineHeight: "1.25" }}>
                      We’ve arranged
                      <br />
                      for a room block
                      <br />
                      at the TWA Hotel
                    </h4>
                  </div>
                </div>
              </div>
              <div className="column">
                <div className="column-cell">
                  <h4>
                    Rooms must be booked by
                    <br />
                    <span style={{ fontSize: "24px" }}>April 20, 2022</span>
                  </h4>
                  <Link
                    className="button-link"
                    to="https://be.synxis.com/?adult=1&arrive=2022-05-20&chain=24658&child=0&currency=USD&depart=2022-05-21&group=GLARTW449&hotel=7182&level=hotel&locale=en-US&rooms=1"
                    target="_blank"
                  >
                    ROOM BLOCK
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="border-bottom-wrapper">
          <div className="border-bottom">
            <div className="full-width">
              <div className="infonote">
                <div className="notebox">
                  <p className="notecolumn">
                    We've blocked a limited number of rooms at a competitive
                    rate for the night of Friday, May 20th.
                    <br />
                    <br />
                    <span
                      style={{
                        color: "rgba(63, 63, 63, 0.5)",
                        fontSize: "12px",
                        lineHeight: "16px",
                      }}
                    >
                      We can't guarantee that rate for other dates!
                    </span>
                  </p>
                  <p className="notecolumn">
                    If you're extending your stay:
                    <br />
                    <br />
                    Book your room for 05/20 at the link above, then{" "}
                    <Link
                      className="inline-link"
                      style={{
                        textDecoration: "underline",
                        whiteSpace: "nowrap",
                      }}
                      to="https://www.twahotel.com/contact"
                      target="_blank"
                    >
                      contact the TWA hotel
                    </Link>{" "}
                    to add nights.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
